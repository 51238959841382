export class LocaleDescription {
    code: string;
    walsCode: string | null;
    name: string;
    nameEnglish: string;
    url: string;
    published: boolean;
    symbol: string;
    family: string;
    extra: string | null;

    constructor(
        code: string,
        walsCode: string | null,
        name: string,
        nameEnglish: string,
        url: string,
        published: boolean,
        symbol: string,
        family: string,
        extra: string | null = null,
    ) {
        this.code = code;
        this.walsCode = walsCode;
        this.name = name;
        this.nameEnglish = nameEnglish;
        this.url = url;
        this.published = published;
        this.symbol = symbol;
        this.family = family;
        this.extra = extra;
    }

    matches(filter: string): boolean {
        const filterNormalised = filter.toLocaleLowerCase();

        return this.name.toLocaleLowerCase().includes(filterNormalised) ||
            this.nameEnglish.toLocaleLowerCase().includes(filterNormalised) ||
            (this.extra ?? '').toLocaleLowerCase().includes(filterNormalised) ||
            this.code.toLocaleLowerCase() === filterNormalised ||
            (this.walsCode ?? '').toLocaleLowerCase() === filterNormalised;
    }
}

/* eslint-disable */
export default [
    new LocaleDescription('de', 'ger', 'Deutsch',     'German',            'https://de.pronouns.page',  true, 'ß', 'germanic'),
    new LocaleDescription('es', 'spa', 'Español',     'Spanish',           'https://pronombr.es',       true, 'ñ', 'romance'),
    new LocaleDescription('eo', null,  'Esperanto',   'Esperanto',         'https://eo.pronouns.page',  false, 'ĥ', 'constructed'),
    new LocaleDescription('en', 'eng', 'English',     'English',           'https://en.pronouns.page',  true, 'þ', 'germanic'),
    new LocaleDescription('et', 'est', 'Eesti keel',  'Estoanian',         'https://et.pronouns.page',  true, 'õ', 'finnish'),
    new LocaleDescription('fr', 'fre', 'Français',    'French',            'https://pronoms.fr',        true, 'ç', 'romance'),
    new LocaleDescription('gl', 'glc', 'Galego',      'Galician',          'https://gl.pronouns.page',  false, 'ñ', 'romance'), // symbol duplicate with spanish
    new LocaleDescription('he', 'heb', 'עברית',       'Hebrew',            'https://he.pronouns.page',  false, 'ע', 'semitic'), // // not entirely sure about the languange family
    new LocaleDescription('it', 'ita', 'Italiano',    'Italian',           'https://it.pronouns.page',  false, 'à', 'romance'),
    new LocaleDescription('lad','lno', 'Ladino',      'Ladin',             'https://lad.pronouns.page', true, 'ny', 'romance', 'Djudezmo'),
    new LocaleDescription('nl', 'dut', 'Nederlands',  'Dutch',             'https://nl.pronouns.page',  true, 'ĳ', 'germanic'),
    new LocaleDescription('no', 'nor', 'Norsk',       'Norwegian',         'https://no.pronouns.page',  true, 'æ', 'germanic', 'Bokmål'), // å might be better, but it's used for swedish
    new LocaleDescription('pl', 'pol', 'Polski',      'Polish',            'https://zaimki.pl',         true, 'ą', 'slavic'),
    new LocaleDescription('pt', 'por', 'Português',   'Portuguese',        'https://pt.pronouns.page',  true, 'ã', 'romance'),
    new LocaleDescription('ro', 'rom', 'Română',      'Romanian',          'https://ro.pronouns.page',  true, 'ă', 'romance'),
    new LocaleDescription('sv', 'swe', 'Svenska',     'Swedish',           'https://sv.pronouns.page',  true, 'å', 'germanic'),
    new LocaleDescription('tr', 'tur', 'Türkçe',      'Turkish',           'https://tr.pronouns.page',  true, 'ğ', 'turkic'),
    new LocaleDescription('vi', 'vie', 'Tiếng Việt',  'Vietnamese',        'https://vi.pronouns.page',  true, 'ớ', 'vietic'),
    new LocaleDescription('ar', 'ams', 'العربية',     'Arabic',            'https://ar.pronouns.page',  true, 'ش', 'semitic', 'الفصحى'),
    new LocaleDescription('ru', 'rus', 'Русский',     'Russian',           'https://ru.pronouns.page',  true, 'й', 'slavic'),
    new LocaleDescription('ua', 'ukr', 'Українська',  'Ukrainian',         'https://ua.pronouns.page',  true, 'ї', 'slavic'),
    new LocaleDescription('ja', 'jpn', '日本語',       'Japanese',          'https://ja.pronouns.page',  true, 'の', 'japonic'),
    new LocaleDescription('ko', 'kor', '한국어',        'Korean',            'https://ko.pronouns.page',  false, '인', 'koreanic'),
    new LocaleDescription('yi', 'ydd', 'ייִדיש',       'Yiddish',           'https://yi.pronouns.page',  false, 'ש', 'germanic'),
    new LocaleDescription('zh', 'mnd', '中文',         'Mandarin Chinese',  'https://zh.pronouns.page',  true, '人', ''),
    new LocaleDescription('tok', null, 'toki pona',   'Toki Pona',         'https://tok.pronouns.page', false, '⊡', 'constructed'),
];
/* eslint-enable */
