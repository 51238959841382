import { defineNuxtPlugin, useRequestHeaders } from 'nuxt/app';

const SAFARI_REGEX = /^((?!chrome|android).)*safari/i;

export default defineNuxtPlugin(() => {
    const headers = useRequestHeaders();

    const isSafari = () => {
        if (process.server && headers && headers['user-agent']) {
            return SAFARI_REGEX.test(headers['user-agent']);
        }

        if (process.client) {
            return SAFARI_REGEX.test(window.navigator.userAgent);
        }

        return false;
    };

    return {
        provide: {
            isSafari,
        },
    };
});
