import { default as abuseReportseYSV4Dicu7Meta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93NJbKaUf0FSMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/admin/auditLog/[username]/[id].vue?macro=true";
import { default as indexwcVaMe7pc5Meta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/admin/index.vue?macro=true";
import { default as moderation0AQwGOr1H6Meta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/admin/moderation.vue?macro=true";
import { default as pendingBans0L9BnVinfTMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/admin/pendingBans.vue?macro=true";
import { default as profilesnvciqyzne8Meta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/admin/profiles.vue?macro=true";
import { default as indexWdANzOeJ35Meta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/admin/timesheets/index.vue?macro=true";
import { default as overview0Y22LeS8JpMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingSSefaWoDXyMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingn6NdcBMJPtMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/admin/translations/missing.vue?macro=true";
import { default as usersyY8lq9I8ruMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/admin/users.vue?macro=true";
import { default as api5GsGMthTxJMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/api.vue?macro=true";
import { default as _91slug_93XScobvXuzaMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/blog/[slug].vue?macro=true";
import { default as indexUgW00IqwBEMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93LCWC3PM89tMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93evtXBB4oftMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminUhy2OwHoqYMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/census/admin.vue?macro=true";
import { default as indexsekbWAOxrQMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/census/index.vue?macro=true";
import { default as contactq5WGimjUMrMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/contact.vue?macro=true";
import { default as design6mPVAoMCCNMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/design.vue?macro=true";
import { default as englishfdo4rVFMWhMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/english.vue?macro=true";
import { default as faqDncytWE0LGMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/faq.vue?macro=true";
import { default as inclusiveQ2JYm9zqxZMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/inclusive.vue?macro=true";
import { default as indexTodYsNM0GkMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/index.vue?macro=true";
import { default as licenseY6VMXLXaFaMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/license.vue?macro=true";
import { default as academicznTZHKnNqyMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/links/academic.vue?macro=true";
import { default as indexcWh1H5yq1uMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/links/index.vue?macro=true";
import { default as media6XTzhph5PCMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/links/media.vue?macro=true";
import { default as translinguisticsmWz013u0mBMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/links/translinguistics.vue?macro=true";
import { default as zineVk6VK91HoyMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/links/zine.vue?macro=true";
import { default as namesShsQkPHnQhMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/names.vue?macro=true";
import { default as index7jJpnHC542Meta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/nouns/index.vue?macro=true";
import { default as templateszfdAkRjBtXMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/nouns/templates.vue?macro=true";
import { default as people3FvS1OYi3tMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/people.vue?macro=true";
import { default as privacy3yi5M2VitZMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/privacy.vue?macro=true";
import { default as _91username_93ZNZOB4OkotMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_93n9okcdRx0cMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/profile/card-[username].vue?macro=true";
import { default as editorvopTd280ysMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/profile/editor.vue?macro=true";
import { default as anylLLHxkRRg5Meta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/pronouns/any.vue?macro=true";
import { default as avoidingsAfilSjq4zMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/pronouns/avoiding.vue?macro=true";
import { default as indexLft7suazU3Meta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/pronouns/index.vue?macro=true";
import { default as mirrorWJPf4lYXuWMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/pronouns/mirror.vue?macro=true";
import { default as pronounZzTyKJGRC4Meta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/pronouns/pronoun.vue?macro=true";
import { default as sourceszsbP4y2vVUMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/sources.vue?macro=true";
import { default as team8L0NRER2vRMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/team.vue?macro=true";
import { default as terminology8fNcp60RmZMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/terminology.vue?macro=true";
import { default as termslCn93x9SYGMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/terms.vue?macro=true";
import { default as user4IElY35TH4Meta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/user.vue?macro=true";
import { default as workshopsNND1IBkGwzMeta } from "/home/admin/www/pronombr.es/release/20240916201851/pages/workshops.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReportseYSV4Dicu7Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/admin/abuseReports.vue").then(m => m.default || m)
  },
  {
    name: "admin-auditLog-username-id",
    path: "/admin/auditLog/:username()/:id()",
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/admin/auditLog/[username]/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/admin/moderation.vue").then(m => m.default || m)
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBans0L9BnVinfTMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/admin/pendingBans.vue").then(m => m.default || m)
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/admin/profiles.vue").then(m => m.default || m)
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/admin/timesheets/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/admin/timesheets/overview.vue").then(m => m.default || m)
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/admin/translations/awaiting.vue").then(m => m.default || m)
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/admin/translations/missing.vue").then(m => m.default || m)
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    name: "api",
    path: "/api",
    meta: api5GsGMthTxJMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/api.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93XScobvXuzaMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexUgW00IqwBEMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93LCWC3PM89tMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/calendar/[[year]].vue").then(m => m.default || m)
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93evtXBB4oftMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/calendar/[year]-[month]-[day].vue").then(m => m.default || m)
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminUhy2OwHoqYMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/census/admin.vue").then(m => m.default || m)
  },
  {
    name: "census",
    path: "/census",
    meta: indexsekbWAOxrQMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/census/index.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactq5WGimjUMrMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/design.vue").then(m => m.default || m)
  },
  {
    name: "english",
    path: "/english",
    meta: englishfdo4rVFMWhMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/english.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqDncytWE0LGMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusiveQ2JYm9zqxZMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/inclusive.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/license.vue").then(m => m.default || m)
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academicznTZHKnNqyMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/links/academic.vue").then(m => m.default || m)
  },
  {
    name: "links",
    path: "/links",
    meta: indexcWh1H5yq1uMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/links/index.vue").then(m => m.default || m)
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: media6XTzhph5PCMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/links/media.vue").then(m => m.default || m)
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticsmWz013u0mBMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/links/translinguistics.vue").then(m => m.default || m)
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zineVk6VK91HoyMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/links/zine.vue").then(m => m.default || m)
  },
  {
    name: "names",
    path: "/names",
    meta: namesShsQkPHnQhMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/names.vue").then(m => m.default || m)
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: index7jJpnHC542Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/nouns/index.vue").then(m => m.default || m)
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templateszfdAkRjBtXMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/nouns/templates.vue").then(m => m.default || m)
  },
  {
    name: "people",
    path: "/people",
    meta: people3FvS1OYi3tMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/people.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacy3yi5M2VitZMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93ZNZOB4OkotMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/profile/[username].vue").then(m => m.default || m)
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_93n9okcdRx0cMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/profile/card-[username].vue").then(m => m.default || m)
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editorvopTd280ysMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/profile/editor.vue").then(m => m.default || m)
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anylLLHxkRRg5Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/pronouns/any.vue").then(m => m.default || m)
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidingsAfilSjq4zMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/pronouns/avoiding.vue").then(m => m.default || m)
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexLft7suazU3Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/pronouns/index.vue").then(m => m.default || m)
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorWJPf4lYXuWMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/pronouns/mirror.vue").then(m => m.default || m)
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronounZzTyKJGRC4Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/pronouns/pronoun.vue").then(m => m.default || m)
  },
  {
    name: "sources",
    path: "/sources",
    meta: sourceszsbP4y2vVUMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/sources.vue").then(m => m.default || m)
  },
  {
    name: "team",
    path: "/team",
    meta: team8L0NRER2vRMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/team.vue").then(m => m.default || m)
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminology8fNcp60RmZMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/terminology.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    meta: termslCn93x9SYGMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "user",
    path: "/user",
    meta: user4IElY35TH4Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/user.vue").then(m => m.default || m)
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopsNND1IBkGwzMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20240916201851/pages/workshops.vue").then(m => m.default || m)
  }
]