import { Feed } from 'feed';
import { defineNuxtRouteMiddleware, useNuxtApp, useRuntimeConfig } from 'nuxt/app';
import parseMarkdown from '~/src/parseMarkdown.ts';
import useConfig from '~/composables/useConfig.ts';
import type { Post } from '~/server/express/blog.ts';

export default defineNuxtRouteMiddleware(async (to) => {
    const { $translator: translator, ssrContext } = useNuxtApp();
    const res = ssrContext?.event.node.res;

    if (!res || to.path !== '/blog.atom') {
        return;
    }

    const config = useConfig();
    const runtimeConfig = useRuntimeConfig();

    const posts = await $fetch<Post[]>('/api/blog');

    const feed = new Feed({
        title: `${translator.translate('title')} • ${translator.translate('links.blog')}`,
        description: translator.translate('description'),
        id: runtimeConfig.public.baseUrl,
        link: `${runtimeConfig.public.baseUrl}/blog.atom`,
        language: config.locale,
        image: `${runtimeConfig.public.baseUrl}/icon.png`,
        favicon: `${runtimeConfig.public.baseUrl}/icon.png`,
        updated: new Date(posts[0].date),
        copyright: '',
    });

    for (const post of posts) {
        const markdown = (await import(`../data/blog/${post.slug}.md`)).default;
        const parsed = await parseMarkdown(markdown, translator);

        feed.addItem({
            title: post.title,
            id: `${runtimeConfig.public.baseUrl}/${config.links.blogRoute}/${post.slug}`,
            link: `${runtimeConfig.public.baseUrl}/${config.links.blogRoute}/${post.slug}`,
            description: parsed.intro ?? undefined,
            content: parsed.content ?? undefined,
            author: post.authors.map((author) => ({
                name: author,
                link: author.startsWith('@') ? `${runtimeConfig.public.baseUrl}/${author}` : undefined,
            })),
            date: new Date(post.date),
            image: post.hero ? `${runtimeConfig.public.baseUrl}${post.hero.src}` : undefined,
        });
    }

    res.setHeader('Content-Type', 'application/rss+xml; charset=utf-8');
    res.end(feed.atom1());

    return new Promise(() => {}); // halt execution
});
