import { useRuntimeConfig } from 'nuxt/app';
import type { RuntimeConfig } from 'nuxt/schema';
import { defineStore } from 'pinia';
import { buildDict, parseUserJwt } from '../src/helpers.ts';
import type { Day } from '../src/calendar/helpers.ts';
import type { Account, User } from '../src/user.ts';
import type { Translator } from '../src/translator';

export interface RootState {
    token: string | null;
    user: User | null;
    preToken: string | null;
    spelling: string | null;
    highlightedMorphemes: Set<string>;
    lightboxUrl: string | null;
    selectedDay: Day | null,
    darkMode: boolean;
    translationModeVisible: boolean;
    translationMode: boolean;
    translationChanges: Record<string, string>;
    adPlaceholdersVisible: boolean;
    reducedItems: boolean;
    accounts: Record<string, Account>;
    runtimeConfig: RuntimeConfig;
}

export const useMainStore = defineStore('main', {
    state: (): RootState => ({
        token: null,
        user: null,
        preToken: null,
        spelling: null,
        highlightedMorphemes: new Set(),
        lightboxUrl: null,
        selectedDay: null,
        darkMode: false,
        translationModeVisible: false,
        translationMode: false,
        translationChanges: {},
        adPlaceholdersVisible: false,
        reducedItems: false,
        accounts: {},
        runtimeConfig: useRuntimeConfig(),
    }),
    actions: {
        setToken(token: string | null) {
            if (!token) {
                this.token = null;
                this.user = null;
                return;
            }

            const publicKey = this.runtimeConfig.public.publicKey;
            const user = parseUserJwt(token, publicKey, this.runtimeConfig.public.allLocalesUrls);

            if (user && user.mfaRequired) {
                this.preToken = token;
            }

            if (user && user.authenticated) {
                this.preToken = null;
                this.token = token;
                this.user = user;
                return;
            }

            this.token = null;
            this.user = null;
        },
        cancelMfa() {
            this.preToken = null;
        },
        setSpelling(spelling: string | null) {
            this.spelling = spelling;
        },
        highlightMorphemes(morphemes: Set<string>) {
            this.highlightedMorphemes = morphemes;
        },
        setDarkMode(isDark: boolean) {
            this.darkMode = isDark;
        },
        showTranslationMode() {
            this.translationModeVisible = true;
        },
        translationInit() {
            this.translationMode = true;
        },
        translationCommit() {
            this.translationMode = false;
            this.translationChanges = {};
        },
        translationAbort() {
            this.translationMode = false;
            this.translationChanges = {};
        },
        translationPause() {
            this.translationMode = false;
        },
        translate({ translator, key, newValue }: { translator: Translator, key: string, newValue: string }) {
            if (newValue !== translator.get(key, false, false, false)) {
                const translationChanges = { ...this.translationChanges };
                translationChanges[key] = newValue;
                this.translationChanges = translationChanges;
            } else {
                this.translationChanges = buildDict(function* (that) {
                    for (const k in that) {
                        if (!that.hasOwnProperty(k)) {
                            continue;
                        }
                        if (k !== key) {
                            yield [k, that[k]];
                        }
                    }
                }, this.translationChanges);
            }
        },
        restoreTranslations(translations: Record<string, string>) {
            if (translations) {
                this.translationMode = true;
                this.translationChanges = translations;
            } else {
                this.translationMode = false;
                this.translationChanges = {};
            }
        },
        toggleAdPlaceholdersVisible(this) {
            this.adPlaceholdersVisible = !this.adPlaceholdersVisible;
        },
        setReducedItems(value: boolean) {
            this.reducedItems = value;
        },
        setAccounts(accounts: Record<string, Account>) {
            this.accounts = accounts;
        },
    },
});
