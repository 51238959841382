export default [
    'pronoun',
    'direct_object_pronoun', // no example?
    'definite_article', // no example?
    'indefinite_article',
    'plural_pronoun',
    'plural_definite_article',
    'plural_indefinite_article', // no example?
    'plural_direct_object_pronoun',
    'inflection',
    'inflection_c',
] as const;
