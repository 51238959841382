import { defineNuxtRouteMiddleware, navigateTo } from 'nuxt/app';
import useConfig from '../composables/useConfig.ts';

export default defineNuxtRouteMiddleware((to) => {
    const config = useConfig();

    const redirect = config.redirects.find((redirectConfig) => {
        return new RegExp(redirectConfig.from).test(to.path);
    });
    if (redirect) {
        return navigateTo(redirect.to);
    }
});
